'use client';

/* eslint-disable no-unused-vars */
import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { useCookie } from 'react-use';

import { Tag } from '@zealy/design-system';
import { AlignJustifyLine, ElementsLine } from '@zealy/icons';

import { useOnMounted as useMounted } from '#hooks/useDidMount';

export type CommunityLayoutVariant = 'grid' | 'list';

export interface ICommunityLayoutVariantContext {
  variant: CommunityLayoutVariant;
  setVariant(variant: CommunityLayoutVariant): void;
}

const CommunityLayoutVariantContext = createContext<ICommunityLayoutVariantContext>({
  variant: 'grid',
  setVariant: (variant: CommunityLayoutVariant) => undefined,
});

export function CommunityLayoutVariantProvider({ children }: PropsWithChildren<unknown>) {
  const [storedVariant, setStoredVariant] = useCookie('community:layoutvariant');
  const [variant, setVariant] = useState<CommunityLayoutVariant>('grid');

  function __setVariant(newVariant: CommunityLayoutVariant) {
    setVariant(newVariant);
    setStoredVariant(newVariant);
  }

  useMounted(() => {
    setTimeout(() => {
      storedVariant && __setVariant(storedVariant as CommunityLayoutVariant);
    }, 0);
  });

  const context = useMemo<ICommunityLayoutVariantContext>(
    () => ({
      variant,
      setVariant: __setVariant,
    }),
    [variant],
  );

  return (
    <CommunityLayoutVariantContext.Provider value={context}>
      {children}
    </CommunityLayoutVariantContext.Provider>
  );
}

/**
 * Exposes the layout variant context for the community cards
 */
export function useCommunityLayoutVariant() {
  return useContext(CommunityLayoutVariantContext);
}

/**
 * UI component for switching the layout variant between grid and list
 */
export function CommunityLayoutVariantControls() {
  const { variant, setVariant } = useCommunityLayoutVariant();

  return (
    <div className="flex-1 flex-row md:justify-end hidden md:flex">
      <div className="flex flex-row" role="group">
        <Tag
          onClick={() => setVariant('grid')}
          selected={variant === 'grid'}
          leftIcon={<ElementsLine />}
          className="rounded-br-none rounded-tr-none"
        />
        <Tag
          onClick={() => setVariant('list')}
          selected={variant === 'list'}
          leftIcon={<AlignJustifyLine />}
          className="rounded-bl-none rounded-tl-none"
        />
      </div>
    </div>
  );
}
